<template>
  <div>
    <div class="top">
      <el-row>
        <el-col :span="12">
          <div class="left">
            <img :src="user.logo ? user.logo : '@/assets/login.png'" alt="" />
            <div class="name">{{ user.title ? user.title : "" }}</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="rig">
            <!-- <div class="name flex" @click="xiaoxi">
              <i  class="el-icon-bell color-icon"></i>消息
            </div> -->
            <div class="name flex">
              <img src="@/assets/account.png" alt="" />
              <span class="gongsi">{{
                user.title ? user.title : "平台用户"
              }}</span>
              <i class="el-icon-arrow-down"></i>

              <div class="account">
                <div class="account-top">
                  <img src="@/assets/account.png" alt="" />
                  <div>
                    <div class="gs-name">
                      {{ user.title ? user.title : "平台用户"
                      }}<span>{{ user.loginname }}</span>
                    </div>
                  </div>
                </div>
                <div class="account-box">
                  <div class="item" @click="editPass">
                    <div>修改密码</div>
                    <i class="el-icon-arrow-right"></i>
                  </div>
                  <!-- <div class="item not-allowed">
                    <div>我的账号</div>
                    <i class="el-icon-arrow-down"></i>
                  </div>
                  <div class="space"></div>
                  <div class="item">
                    <div>授权管理</div>
                    <i class="el-icon-arrow-down"></i>
                  </div> -->
                </div>
                <div class="quit" @click="quit">退出</div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- dialog -->
    <el-dialog
      :title="title"
      center
      :width="dialogWidth"
      :visible.sync="dialogFormVisible"
      @close="dialogFormClose('form')"
    >
      <el-form
        v-if="dialogType == 'edit'"
        :model="form"
        :rules="rules"
        :label-width="formLabelWidth"
        ref="form"
      >
        <el-form-item label="旧密码" prop="oldpassword">
          <el-input
            v-model="form.oldpassword"
            placeholder="请输入旧密码"
            style="width: 90%"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input
            v-model="form.password"
            placeholder="请输入新密码"
            style="width: 90%"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmpassword">
          <el-input
            v-model="form.confirmpassword"
            placeholder="请再次确认密码"
            style="width: 90%"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer center">
        <el-button @click="dialogFormClose('form')">取 消</el-button>
        <el-button type="primary" @click="dialogFormSubmit('form')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";

export default Vue.extend({
  props: {},
  data() {
    return {
      title: "",
      dialogType: "",
      dialogWidth: "50%",
      formLabelWidth: "100px",
      dialogFormVisible: false,

      form: {
        oldpassword: "",
        password: "",
        confirmpassword: "",
      },

      rules: {
        oldpassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
        passwordAgain: [
          {
            validator: (rule, value, callback) => {
              if (value !== this.form.password) {
                callback(new Error("两次输入的密码不一致"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(["user"]),
    user: {
      get() {
        return this.$store.state.user;
      },
      set(val) {
        this.$store.state.user = val;
      },
    },
  },
  mounted() {
    let logStatu = JSON.parse(localStorage.getItem("user"));
    if (logStatu == null) {
      // this.$message.warning("登录已过期")
      localStorage.removeItem("user");
      this.$router.push({ name: "login" });
    } else {
      this.getInfo();
    }
  },
  methods: {
    ...mapMutations(["setuser"]),
    // 获取个人信息（提现余额、提现说明）
    getInfo() {
      this.$api
        .getInfo({})
        .then((res) => {
          if (res.code == "success") {
            this.user = res.data;
            localStorage.setItem("user", JSON.stringify(res.data));
            this.setuser(this.user);

            // 动态修改页签标题与图标
            let relIcon = document.head.querySelector("link[rel='icon']");
            relIcon.href = res.data.logo;
            document.title = res.data.title;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    // 退出登录
    quit() {
      this.$confirm("真的要注销登录吗 ?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 获取当前URL
          const url = new URL(window.location.href);
          // 删除token参数
          url.searchParams.delete("token");
          // 重新加载页面，使用新的URL
          window.location.href = (url.origin + "/web").toString();
          localStorage.removeItem("user");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 修改密码
    editPass() {
      this.title = "修改密码";
      this.dialogType = "edit";
      this.dialogFormVisible = true;
    },
    // 弹窗关闭逻辑
    dialogFormClose(formName) {
      this.$refs[formName].resetFields(); // 移除表单验证
      Object.keys(this.form).forEach((key) => {
        this.form[key] = "";
      }); // 清除数据缓存
      this.dialogFormVisible = false; // 关闭弹窗
    },
    // 表单提交
    dialogFormSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.dialogType === "edit") {
            this.$api
              .editPass(this.form)
              .then((res) => {
                if (res.code == "success") {
                  this.$message.success(res.msg);
                  this.dialogFormVisible = false;
                  this.getInfo();
                } else {
                  this.$message.error(res.msg);
                }
                this.loading = false;
              })
              .catch((err) => {
                this.$message.error(err.msg);
                this.loading = false;
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    xiaoxi() {
      this.$emit("getrusult", true);
    },
  },
});
</script>

<style scoped lang="less">
.top {
  background: #fff;
  box-shadow: 0 0 5px rgb(230, 230, 230);
  color: #101011;
  font-size: 20px;
  height: 72px;
  padding: 0 24px;
  z-index: 500;
  position: relative;

  .name {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 40px;
    font-size: 14px;
    color: #101011;
    font-weight: 600;
  }

  .color-icon {
    font-size: 22px;
    font-weight: 600;
    margin-right: 10px;
  }

  .left {
    display: flex;
    align-items: center;
    height: 72px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 10px;
    }
  }

  .rig {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.gongsi {
  color: #101011;
  font-family: PingFang SC;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin: 0 6px 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
}

//  hover效果
.khd {
  position: relative;
}

.khd:hover .khd-hover {
  display: block;
}

.khd-hover {
  display: none;
  width: 277px;
  padding: 40px 0;
  position: absolute;
  left: 0;
  top: 50px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px #f5f5f5;
  text-align: center;

  img {
    width: 120px;
    height: 120px;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
  }

  .mask {
    margin: 10px 0;
    font-size: 12px;
    color: #95989d;
  }

  .edition {
    font-size: 14px;
    color: #95989d;
  }
}

.flex {
  height: 72px;
  display: flex;
  align-items: center;
  align-items: center;
  position: relative;

  img {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin-right: 15px;
  }
}

.flex:hover .account {
  display: block;
}

.account {
  display: none;
  width: 300px;
  position: absolute;
  right: 0;
  top: 60px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px #f5f5f5;

  .account-top {
    cursor: auto;
    padding: 30px 0;
    display: flex;
    align-items: center;

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin: 0 20px;
    }

    .gs-name {
      span {
        background: #f2f7ff;
        color: #3879fc;
        padding: 2px 5px;
        border-radius: 5px;
        font-size: 12px;
      }
    }

    .gs-id {
      margin-top: 10px;
      font-size: 12px;
      color: #888;
    }
  }

  .account-box {
    padding: 20px;
    border-top: 1px solid #f1f1f1;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .not-allowed {
      cursor: not-allowed;
      color: #888;
    }

    .space {
      height: 20px;
    }
  }

  .quit {
    padding: 15px 0;
    text-align: center;
    color: #f4624e;
    font-weight: 600;
    border-top: 1px solid #f1f1f1;
  }
}
</style>
