<template>
  <div>
    <div class="outer" :class="type == 1?'pc':'iphone'">
      <div class="top">
        <div class="header">
          <!-- <img  src="../assets/login.png" alt=""> -->
          <!-- <span></span> -->
        </div>
        <div class="desc"></div>
      </div>
      <div class="box">
        <div class="tit">账号密码登录</div>

        <div class="item">
          <el-input
          prefix-icon="el-icon-user"
            v-model="username"
            placeholder="输入账户名"
            clearable
          ></el-input>
        </div>

        <div class="item">
          <el-input
            prefix-icon="el-icon-view"
            v-model="password"
            placeholder="输入密码"
            show-password
          ></el-input>
        </div>
        <!-- <div class="item">
          <el-checkbox v-model="checked">自动登录</el-checkbox>
        </div> -->
        <div class="button" @click="submit">登录</div>
        <div class="tips" >温馨提示：忘记密码请联系管理员</div>
      </div>
    </div>
  </div>
</template>

<script >
import { mapState, mapMutations } from 'vuex';
export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
      checked:false,

      store:'',
      logo:'',

      token:'',

      type:''
    };
  },
  computed: {
		...mapState(['user', 'setting'])
	},
  created(){
    let token = window.location.search.substring(7, window.location.search.length)
    console.log('token', token)

    // 后台跳转进入店铺后台时，携带token时自动登录
    if (token) {
      if (this.$cookies.get('current_time')) { //cookies无current_time参数前端控制token过期
        let res = {token}
        let user = JSON.stringify(res)
        localStorage.setItem('user',user)
        localStorage.setItem("token", token)
        this.setuser(user)
        this.$router.replace('/p/home')

        // 移除token参数
        var currentURL = window.location.href
        var updatedURL = currentURL.replace(/[?&]token=([^&]*)/, '')
        // 如果更新后的URL末尾是'?'，则移除它
        if (updatedURL.slice(-1) === '?') {
          updatedURL = updatedURL.slice(0, -1)
        }
        // 使用replaceState方法将更新后的URL替换当前页面的URL
        history.replaceState(null, null, updatedURL)
      }
      else{
        this.$message.warning('登录过期')
        localStorage.removeItem('user')
        this.$router.replace({name:'login'})
      }
    }
  },
  mounted(){
    let status = localStorage.user
    if(status != undefined){
        let user = JSON.parse(localStorage.user) 
        this.token = user.token
        this.setuser(user)
        this.$router.replace('/p/home')
    }
    var mobileAgent = new Array("iphone", "ipod", "ipad", "android", "mobile", "blackberry", "webos", "incognito", "webmate", "bada", "nokia", "lg", "ucweb", "skyfire");
      var browser = navigator.userAgent.toLowerCase();
      for (var i = 0; i < mobileAgent.length; i++) {
          if (browser.indexOf(mobileAgent[i]) != -1) {
              this.type = 0
              break;
          }else{
              this.type = 1
          }
      }
     
  },
  methods: {
    ...mapMutations(['setuser', 'setsetting']),
    submit() {
      let data = {
        user_name: this.username,
        password:this.password
      }
      this.$api.login(data).then(res=>{
        if(res.code == 'success'){
          let user = JSON.stringify(res.data)
          localStorage.setItem('user',user)
          this.setuser(user)
          this.$message.success(res.msg)
          setTimeout(e=>{
            this.$router.replace('/p/home')
          },500)
        }else{
          this.$message.error(res.msg)
        }
      }).catch(err=>{
        this.$message.error(err.msg)
      });
    },
  },
};
</script>

<style scoped lang="less">
.outer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.pc{
  background: url(../assets/pc.jpg);
  background-size:cover;
}
.iphone{
  background-size: 100% 100%;
}
.top{
  .header{
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
  }
  img{
    width: 80px;
    height: 80px;
    border-radius: 10px;
  }
  span{
    font-size: 33px;
    color: #fff;
    font-family: Avenir,Helvetica Neue,Arial,Helvetica,sans-serif;
    font-weight: 600;
  }
  .desc{
    font-size: 18px;
    color: #fff;
    margin-top: 12px;
    margin-bottom: 40px;
  }
}
.box {
  box-sizing: border-box;
  width: 25%;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #000;
  background: #fff;
  .tit {
    padding-bottom: 10px;
    display: inline-block;
    border-bottom: 2px solid #1890ff;
    cursor: pointer;
    text-align: center;
    color: #1890ff;
    font-weight: 500;
  }
  .colors {
    position: absolute;
    left: 0;
    bottom: -40px;
    width: 100%;
    color: red;
    font-size: 24px;
  }
  .item {
    position: relative;
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    input {
      padding-left: 10px;
    }
    i {
      color: #606266;
      font-size: 20px;
      margin-right: 20px;
    }
  }
  .button {
    cursor: pointer;
    margin: 10% 0;
    padding: 10px 0;
    width: 100%;
    background: #1890FF;
    color: #fff;
    text-align: center;
  }
  .tips{
    width: 100%;
    font-size: 12px;
    color: rgba(0,0,0,.65);
    text-align: left;
  }
}

@media screen and (max-width:750px){
  .box{
    width: 90%;
  }

  .top{
  img{
    width: 44px;
    height: 44px;
  }
  span{
    font-size:26px;
    font-weight: 600;
  }
  .desc{
    font-size: 14px;
    color: #fff;
    margin-top: 12px;
    margin-bottom: 20px;
  }
}
}
</style>


